import { Meal, MealState } from '../../services/meal.service';
import { Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import { CSSProperties } from 'react';

interface Props {
    meal: Meal;
    onClick: (m: Meal) => void;
}

const cardStyle: CSSProperties = {
    border: '1px solid #f0f0f0',
    boxSizing: 'border-box',
    margin: 0,
    padding: '6px 12px',
    color: 'rgba(0, 0, 0, 0.88)',
    borderRadius: '8px',
    backgroundColor: 'white',
    flex: '0 0 auto',
    cursor: 'pointer',
};

const stateIcons: Record<MealState, string> = {
    OPEN: '🍴',
    REGISTERED: '✅',
    CLOSED: '🔒'
};

export default function FutureMealCard({ meal, onClick }: Props) {
    return <div style={cardStyle} onClick={() => onClick(meal)}>
        <Flex align="center" justify="center" gap={8}>
            <Typography.Text strong style={{ fontSize: '2em' }}>{stateIcons[meal.state]}</Typography.Text>
            <Flex vertical>
                <Typography.Text strong>{meal.name}</Typography.Text>
                <Typography.Text>{dayjs(meal.date).format('L')}</Typography.Text>
            </Flex>
        </Flex>
    </div>;
};
