import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Location } from 'react-router-dom';
import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

export interface NavBarItem {
    name: string;
    href: string;
}

interface NavBarProps {
    items: NavBarItem[];
}

interface NavBarItemBoxProps {
    item: NavBarItem;
    onClick?: () => void;
    isSelected: boolean;
}

function getCurrentUrl(location: Location<any>): Array<string> {
    return location.pathname.split('/');
}

function NavBarItemBox({ item, isSelected }: NavBarItemBoxProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return <Button style={{
        borderWidth: 2,
        borderRadius: '10px',
        padding: '2dp 1dp',
    }}
                   type={isSelected ? 'default' : 'dashed'}
                   size="small"
                   color={isSelected ? 'primary' : 'default'}
                   onClick={() => navigate(item.href)}>
        {t(item.name)}
    </Button>;
}

export default function NavBar({ items }: NavBarProps) {
    const location = useLocation();
    const navigate = useNavigate();

    const [selected, setSelected] = useState<NavBarItem | null>(null);

    useEffect(() => {
        const url = getCurrentUrl(location);
        const newSelected = items.find(
            item => url.some(r => r === item.href)
        );
        if (newSelected === undefined &&  items.length > 0) {
            navigate(items[0].href);
        } else {
            setSelected(newSelected ?? null);
        }
    }, [items, location.pathname, navigate]);

    return <Flex gap={10}
                 style={{
                     overflowX: 'auto',
                     overflowY: 'hidden',
                     margin: '8px 16px',
                 }}>
        {items.map(item => <NavBarItemBox item={item} key={item.href}
                                          isSelected={item === selected}/>)}
    </Flex>;
}
