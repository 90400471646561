import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {i18nextPlugin as showTranslationsPlugin} from 'translation-check'

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .use(showTranslationsPlugin)
    .init({
        fallbackLng: 'ru',
        debug: process.env.NODE_ENV !== 'production',
    });
