import React from 'react';
import { Flex } from 'antd';
import { useAuth, useRoleCheck } from '../../hooks/auth.hooks';
import DashboardSection from '../../components/user_dashboard/DashboardSection';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import FetchAllFutureMeals from '../../components/user_dashboard/FetchAllFutureMeals';
import FutureMealCard from '../../components/user_dashboard/FutureMealCard';
import UserDebtOverview from '../../components/user_dashboard/UserDebtOverview';
import { INotificationsService } from '../../services/notifications.service';
import { Meal } from '../../services/meal.service';
import { useNotificationsService } from '../../services/types.service';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

function navigateToMeal(meal: Meal, navigate: NavigateFunction, notificationsService: INotificationsService, t: TFunction<"translation", undefined>) {
    switch(meal.state) {
        case "OPEN":
            navigate(`/registration/${meal.id}`, { replace: false });
            break;
        case "REGISTERED":
            notificationsService.error(t('ERROR_MEAL_REGISTERED'));
            break;
        case "CLOSED":
            notificationsService.error(t('ERROR_MEAL_CLOSED'));
            break;
    }
}

export default function UserDashboard() {
    const user = useAuth();
    const navigate = useNavigate();
    const notificationsService = useNotificationsService();
    const { t } = useTranslation();
    const isAdmin = useRoleCheck('ADMIN');

    return <Flex style={{
        margin: '8px 16px',
        height: '100%'
    }} gap={16} vertical>
        <DashboardSection
            title={t('DASHBOARD_GREETING', { name: user.user?.name ?? "User" })}
            backgroundColor="transparent"
            titleColor="black"
        />
        {isAdmin ? <DashboardSection
            title={t(`DASHBOARD_ADMIN`)}
            withArrow
            onClick={() => {
                navigate('/admin', { replace: false });
            }}
            backgroundColor="#831330"
            titleColor="white"
        /> : null}
        <Flex vertical gap={4}>
            <DashboardSection
                title={t(`DASHBOARD_NEXT_MEALS`)}
                backgroundColor="#831330"
                titleColor="white"
            >
                <div style={{ marginTop: "0.5em" }}>
                <FetchAllFutureMeals
                    render={m => <FutureMealCard meal={m}
                                                 onClick={m => navigateToMeal(m, navigate, notificationsService, t)}/>}/>
                </div>
            </DashboardSection>
            <DashboardSection
                title={t(`DASHBOARD_DEBTS`)}
                backgroundColor="#831330"
                titleColor="white"
            >
                <UserDebtOverview/>
            </DashboardSection>
        </Flex>
    </Flex>;
}
